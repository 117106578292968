import { LineItem, Order } from 'api/orders/types';
import * as React from 'react';
import cn from 'classnames';
import Span from 'styleguide/components/Span/Span';
import startCase from 'lodash-es/startCase';
import Loader from 'styleguide/components/Loader/Loader';
import DateTimeZone from 'styleguide/components/DateTimeZone/DateTimeZone';

interface Props {
  lineItem: LineItem;
  deliveryDate: string;
  order: Order;
}

const ProductDetails = ({ lineItem, deliveryDate, order }: Props) => (
  <>
    {lineItem.description.map((item, i) => {
      const key = Object.keys(item)[0];

      return (
        <div key={i} className={cn('flex text-left text-sm !leading-5 -sm:text-xs')}>
          <Span className="mr-2 !font-hvMedium">{`${startCase(key)}: `}</Span>
          {`${item[key]}`}
        </div>
      );
    })}
    {deliveryDate &&
      order.status === 'loaded' &&
      (!order.shipment || (order.shipment && order.shipment.quoteState === 'valid')) && (
        <DateTimeZone
          date={deliveryDate}
          key={lineItem.description.length}
          className="mb-1 mt-1 block text-sm !leading-5 -sm:text-xs"
          fnsFormat="MMM do yyyy h:mma"
          showEndOfDay
        />
      )}
    {deliveryDate && order.status === 'loading' && (
      <Loader dataCy={`lineItemLoadingSpinner-${lineItem.id}`} size="sm" />
    )}
  </>
);

export default ProductDetails;
