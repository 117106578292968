import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { SharedErrors } from 'libs/utils/api/types';
import { BasicResponse } from 'api/types';

export interface UploadResponse {
  uploadId: number;
  signedUrl: string;
}

// POST /uploads
export const postUploads = upload => {
  const url = buildUrl(`/uploads/`);
  return apiCall.post<UploadResponse, SharedErrors>({ url, data: upload });
};

export interface VerifySuccessResults {
  success: boolean;
  url: string;
  createdAt: string;
  id: number;
}

export interface VerifyFailureResults {
  success: boolean;
  status_text: string;
  response_text: string;
}

// get /uploads/:uploadId/verify
export const verifyUpload = (uploadId: number) => {
  const url = buildUrl(`/uploads/${uploadId}/verify`);
  return apiCall.get<VerifySuccessResults, VerifyFailureResults>({ url });
};

// DELETE /uploads/destroy/:id
export const deleteUpload = (uploadId: number) => {
  const url = buildUrl(`/uploads/${uploadId}`);
  return apiCall.delete<BasicResponse, SharedErrors>({ url });
};
