/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import S3Upload from 'react-s3-uploader/s3upload';
import Dropzone from 'react-dropzone';
import { UploadFile } from './UploadArea';

interface Props {
  filename?: string;
  s3Url: string;
  notDropzoneProps?: string[];
  isImage?: (filename: string) => boolean;
  passChildrenProps?: boolean;

  imageComponent?: React.ComponentType;
  fileComponent?: React.ComponentType;
  progressComponent?: React.ComponentType;
  errorComponent?: React.ComponentType;

  children?: React.ReactNode;

  onDrop?: (...args) => File[];
  onError?: (error: Error, file: File) => void;
  onProgress?: (percentage: number, status: string, file: File) => void;
  onFinish?: (wrappedFile: { file: UploadFile; uploadId: number }) => void;

  upload: {
    preprocess?: (file: File, next: (preProcessedFile: File) => void) => void;
    getSignedUrl?: (file: File, callback) => void;
  };

  className?: string | Record<string, string>;
  style?: Record<string, unknown>;
  activeStyle?: Record<string, unknown>;
  rejectStyle?: Record<string, unknown>;
  multiple?: boolean;
}

type DropzoneUpload = {
  filename: string;
  fileUrl: string;
  default: boolean;
  file?: Record<string, never>;
};

type S3Uploaded = {
  file: File;
  uploadId: number;
};

type UploadedFile = DropzoneUpload | S3Uploaded;

interface State {
  uploadedFiles: UploadedFile[];
  uploaderOptions: Record<string, string>;
  progress: number;
  error: Error;
}

export default class DropzoneS3Uploader extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    upload: {},
    className: 'react-dropzone-s3-uploader',
    passChildrenProps: true,
    isImage: filename => filename && filename.match(/\.(jpeg|jpg|gif|png|svg)/i),
    notDropzoneProps: [
      'onError',
      'onFinish',
      's3Url',
      'filename',
      'host',
      'upload',
      'isImage',
      'notDropzoneProps',
    ],
    style: {
      width: 200,
      height: 200,
      border: 'dashed 2px #999',
      borderRadius: 5,
      position: 'relative',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    activeStyle: {
      borderStyle: 'solid',
      backgroundColor: '#eee',
    },
    rejectStyle: {
      borderStyle: 'solid',
      backgroundColor: '#ffdddd',
    },
    multiple: true,
  };

  constructor(props) {
    super(props);
    const uploadedFiles: DropzoneUpload[] = [];
    const { filename } = props;
    if (filename) {
      uploadedFiles.push({
        filename,
        fileUrl: this.fileUrl(props.s3Url, filename),
        default: true,
        file: {},
      });
    }
    this.state = {
      uploadedFiles,
      uploaderOptions: null,
      progress: 0,
      error: null,
    };
  }

  componentDidMount() {
    return this.setUploaderOptions(this.props);
  }

  setUploaderOptions = props => {
    this.setState({
      uploaderOptions: {
        contentDisposition: 'auto',
        uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
        onFinishS3Put: this.handleFinish,
        onProgress: this.handleProgress,
        onError: this.handleError,
        ...props.upload,
      },
    });
  };

  handleProgress = (progress: number, textState: string, file: File) => {
    if (this.props.onProgress) {
      this.props.onProgress(progress, textState, file);
    }
    this.setState({ progress });
  };

  handleError = (err: Error, file: File) => {
    if (this.props.onError) {
      this.props.onError(err, file);
    }
    this.setState({ error: err, progress: null });
  };

  handleFinish = (info: { uploadId: number }, file: File) => {
    const uploadedFile: S3Uploaded = {
      file,
      ...info,
    };

    this.setState(
      prevState => {
        const uploadedFiles = [...prevState.uploadedFiles];

        uploadedFiles.push(uploadedFile);

        return {
          uploadedFiles,
          error: null,
          progress: null,
        };
      },
      () => {
        if (this.props.onFinish) {
          this.props.onFinish(uploadedFile);
        }
      },
    );
  };

  handleDrop = (files: File[]) => {
    let newFiles = { ...files };
    this.setState({ uploadedFiles: [], error: null, progress: null });
    if (this.props.onDrop) {
      newFiles = this.props.onDrop(files);
    }
    const options = {
      files: newFiles,
      ...this.state.uploaderOptions,
    };
    // eslint-disable-next-line no-new
    new S3Upload(options);
  };

  fileUrl = (s3Url, filename) => `${s3Url.endsWith('/') ? s3Url.slice(0, -1) : s3Url}/${filename}`;

  renderImage = ({ uploadedFile }) => (
    <div className="rdsu-image">
      <img src={uploadedFile.fileUrl} alt="Uploaded File" />
    </div>
  );

  renderFile = ({ uploadedFile }) => (
    <div className="rdsu-file">
      <div className="rdsu-file-icon">
        <span className="fa fa-file-o" style={{ fontSize: '50px' }} />
      </div>
      <div className="rdsu-filename">{uploadedFile.file.name}</div>
    </div>
  );

  renderProgress = ({ progress }) => (progress ? <div className="rdsu-progress">{progress}</div> : null);

  renderError = ({ error }) => (error ? <div className="rdsu-error small">{error}</div> : null);

  render() {
    const {
      s3Url,
      passChildrenProps,
      children,
      imageComponent,
      fileComponent,
      progressComponent,
      errorComponent,
      ...dropzoneProps
    } = this.props;

    const ImageComponent = imageComponent || this.renderImage;
    const FileComponent = fileComponent || this.renderFile;
    const ProgressComponent = progressComponent || this.renderProgress;
    const ErrorComponent = errorComponent || this.renderError;

    const { uploadedFiles } = this.state;
    const childProps = { s3Url, ...this.state };
    this.props.notDropzoneProps.forEach(prop => delete dropzoneProps[prop]);

    let content = null;
    if (children) {
      content = passChildrenProps
        ? React.Children.map(children, (child: React.ReactElement) => React.cloneElement(child, childProps))
        : this.props.children;
    } else {
      content = (
        <div>
          {uploadedFiles.map(uploadedFile => {
            const props = {
              key: (uploadedFile as DropzoneUpload).filename,
              uploadedFile,
              ...childProps,
            };
            return this.props.isImage((uploadedFile as DropzoneUpload).fileUrl) ? (
              <ImageComponent {...props} />
            ) : (
              <FileComponent {...props} />
            );
          })}
          <ProgressComponent {...childProps} />
          <ErrorComponent {...childProps} />
        </div>
      );
    }

    return (
      <Dropzone {...dropzoneProps} onError={() => {}} onDrop={this.handleDrop}>
        {content}
      </Dropzone>
    );
  }
}
