import * as React from 'react';
import cn from 'classnames';
import { LineItem } from 'api/orders/types';
import Span from 'styleguide/components/Span/Span';
import startCase from 'lodash-es/startCase';

interface Props {
  lineItem: LineItem;
  withBackground: boolean;
}

const ProductDetailsTabs = ({ lineItem, withBackground }: Props) => {
  const quoterJson = lineItem.quoterJson;
  const tabArray = Array.from({ length: parseInt(quoterJson.numberOfTabs, 10) }, (_, index) => index + 1);
  const tabAttributes = ['numberOfTabs', 'tabPaperType', 'tabMylar'];

  return (
    <div className="ml-4">
      <Span data-cy="tabs" className={cn('mb-2 flex !font-hvBold', withBackground && 'text-blue -md:mb-0')}>
        Tabs
      </Span>
      {tabAttributes.map((tabAttribute, i) => (
        <div key={i} className={cn('flex text-left text-sm !leading-5 -sm:text-xs')}>
          <Span className="mr-2 !font-hvMedium">{`${startCase(tabAttribute)}: `}</Span>
          {`${startCase(quoterJson[tabAttribute])}`}
        </div>
      ))}
      {tabArray.map((tabAttribute, i) => (
        <>
          <div key={i} className={cn('flex text-left text-sm !leading-5 -sm:text-xs')}>
            <Span className="mr-2 !font-hvMedium">{`Tab ${tabAttribute} Text: `}</Span>
            {`${quoterJson[`tab${tabAttribute}Name`]}`}
          </div>
          <div key={i} className={cn('flex text-left text-sm !leading-5 -sm:text-xs')}>
            <Span className="mr-2 !font-hvMedium">{`Tab ${tabAttribute} Page Location: `}</Span>
            {`${quoterJson[`tab${tabAttribute}Page`]}`}
          </div>
        </>
      ))}
    </div>
  );
};

export default ProductDetailsTabs;
